$(function () {
	var moreNum = 6;
	$('.salonMenuStyle__list li:nth-child(n + ' + (moreNum + 1) + ')').addClass('is-hidden');
	$('.salonMenuStyle__stylemore').on('click', function () {
		$('.salonMenuStyle__list li.is-hidden').slice(0, moreNum).fadeIn().removeClass('is-hidden').addClass('is-show');
		if ($('.salonMenuStyle__list li.is-hidden').length == 0) {
			$('.salonMenuStyle__stylemore').fadeOut();
			$('.salonMenuStyle__styleless').addClass('is-show').fadeIn();
		}
	});
	$('.salonMenuStyle__styleless').on('click', function () {
		$('.salonMenuStyle__list li:nth-child(n + ' + (moreNum + 1) + ')').fadeOut().addClass('is-hidden');
		$('.salonMenuStyle__list li.is-show').removeClass('is-show');
		$('.salonMenuStyle__stylemore').fadeIn();
		$(this).fadeOut();
	});
});



$(function () {
	$(".humMenu").on("click", function () {
		$('.navi').toggle();
		$(this).hide();
		$(".navi__closeBtn").show();
	});

	$(".navi__closeBtn").on("click", function () {
		$('.navi').toggle();
		$(this).hide();
		$(".humMenu").show();
	});

	$(".salonMenu__list dt,.salonQa__list dt,.salonMenuPage__list dt,.salonQaPage__list dt").on("click", function () {
		$(this).next("dd").toggle();
		$(this).toggleClass("js-open");
	});
});
$(function () {
	$('a[href^="#"]').click(function () {
		var adjust = -180;
		let speed = 500;
		let href = $(this).attr("href");
		let target = $(href == "#" || href == "" ? 'html' : href);
		let position = target.offset().top + adjust;
		$("html, body").animate({
			scrollTop: position
		}, speed, "swing");
		return false;
	});
});
$(function () {
	var $target = $('.zoom');
	var offset = 200;

	$(window).on('scroll', function () {

		var scroll = $(window).scrollTop();
		var h = $(window).height();

		$target.each(function () {
			var pos = $(this).offset().top;
			if (scroll > pos - h + offset) {
				$(this).addClass('zoomImg');
			}
		})

	}).trigger('scroll');
});
$(function () {

	var lTor = {
		duration: 3000,
		delay: 500,
		distance: '60px',
		origin: 'left'
	};

	var lTor2 = {
		duration: 3000,
		delay: 1500,
		distance: '60px',
		origin: 'left'
	};
	ScrollReveal().reveal('.ltor_moving', lTor);
	ScrollReveal().reveal('.ltor_moving2', lTor2);
});

$(function () {
	$(window).scroll(function () {
		$(".salonMenu,.about__title:nth-of-type(2),.salonMenuStyle,.staffIntro:nth-of-type(1),.salonQaPage__list:nth-of-type(3)")
			.each(
				function () {
					var imgPos = $(this).offset().top;
					var scroll = $(window).scrollTop();
					var windowHeight = $(window).height();
					if (scroll > imgPos - windowHeight + windowHeight / 5) {
						$(".totop").fadeIn();
					} else {
						$(".totop").fadeOut();
					}
				});
	});
});
